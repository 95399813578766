import { FunctionalUtils } from '../../../src/utilities/functionalUtils';
import { verticalPeopleImageMap } from "./vertical-people-imgs-constants"

export class VerticalJob {
   id: number = 0
   valid: boolean = false
   name: string
   vertical: any
   url: string
   skilledImg: string
   constructor(verticalJob: any) {
      this.name = verticalJob.name
      this.vertical = verticalJob.vertical
      this.initJobUrl()
      this.skilledImg = "assets/images/verticals/people/skilled-" + verticalJob.imgId
   }


   initJobUrl() {
      const startingUrl = this.isVerticalOutsourcing() ? "outsource-" : "hire-"
      this.url = FunctionalUtils.convertNameToURL(startingUrl+this.name)
   }

   isVerticalOutsourcing() {
      let verticalName = this.vertical.name
      return verticalName == 'Customer Support' || verticalName == 'IT & Operations'
   }

   getUrl() {
      this.initJobUrl();
      return this.url;
   }

}

export const verticalJobs = [
   new VerticalJob({
      name: 'Application intelligence Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Artificial Intelligence Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Computer and Information Research Scientist',
      vertical: { name: 'AI & Data Science' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Data Analyst',
      vertical: { name: 'AI & Data Science' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Data Architect',
      vertical: { name: 'AI & Data Science' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Data Center System Administrator',
      vertical: { name: 'AI & Data Science' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Data Quality Manager',
      vertical: { name: 'AI & Data Science' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Data Scientist',
      vertical: { name: 'AI & Data Science' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'Hadoop Consultant',
      vertical: { name: 'AI & Data Science' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'R&D Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'Big Data Platform Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'IOT Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'ETL Hadoop Developer',
      vertical: { name: 'AI & Data Science' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Applied Research Scientist',
      vertical: { name: 'AI & Data Science' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Hadoop Big Data Administrator',
      vertical: { name: 'AI & Data Science' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Unified AI Platform Architect',
      vertical: { name: 'AI & Data Science' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Swift Compiler Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Data Visualization Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Robotics Process Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'Neural Networks Design Engineer',
      vertical: { name: 'AI & Data Science' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'Account Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Content Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Content Strategist',
      vertical: { name: 'Business & Marketing' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Digital Marketing Engineer',
      vertical: { name: 'Business & Marketing' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Digital Marketing Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Growth Hacker',
      vertical: { name: 'Business & Marketing' },
      imgId: '22'
   }),
   new VerticalJob({
      name: 'Marketing Technologist',
      vertical: { name: 'Business & Marketing' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Sales Engineer',
      vertical: { name: 'Business & Marketing' },
      imgId: '24'
   }),
   new VerticalJob({
      name: 'SEO Consultant',
      vertical: { name: 'Business & Marketing' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'Social Media Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '31'
   }),
   new VerticalJob({
      name: 'Technical Writer',
      vertical: { name: 'Business & Marketing' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'Content Creator',
      vertical: { name: 'Business & Marketing' },
      imgId: '42'
   }),
   new VerticalJob({
      name: 'Online Sales Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '45'
   }),
   new VerticalJob({
      name: 'Go To Market Manager',
      vertical: { name: 'Business & Marketing' },
      imgId: '50'
   }),
   new VerticalJob({
      name: 'CRM Expert',
      vertical: { name: 'Business & Marketing' },
      imgId: '53'
   }),
   new VerticalJob({
      name: 'Agile Business Analyst',
      vertical: { name: 'Business & Marketing' },
      imgId: '45'
   }),
   new VerticalJob({
      name: 'Business Analyst',
      vertical: { name: 'Business & Marketing' },
      imgId: '66'
   }),
   new VerticalJob({
      name: 'Business Intelligence (BI) Engineer',
      vertical: { name: 'Business & Marketing' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Business Process Engineer',
      vertical: { name: 'Business & Marketing' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Business Systems Analyst',
      vertical: { name: 'Business & Marketing' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Business Intelligence (BI) Developer',
      vertical: { name: 'Business & Marketing' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Saas Business Consultant',
      vertical: { name: 'Business & Marketing' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Cloud Architect',
      vertical: { name: 'Cloud' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Cloud Project Manager',
      vertical: { name: 'Cloud' },
      imgId: '1'
   }),
   new VerticalJob({
      name: 'Cloud Services Developer',
      vertical: { name: 'Cloud' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Cloud System Administrator',
      vertical: { name: 'Cloud' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Cloud System Engineer',
      vertical: { name: 'Cloud' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Virtualization Architect',
      vertical: { name: 'Cloud' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'GPU Cloud Engineer',
      vertical: { name: 'Cloud' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Cloud SRE Engineer',
      vertical: { name: 'Cloud' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'Cloud Security Expert',
      vertical: { name: 'Cloud' },
      imgId: '8'
   }),
   new VerticalJob({
      name: 'Cloud Automation Architect',
      vertical: { name: 'Cloud' },
      imgId: '9'
   }),
   new VerticalJob({
      name: 'Application Support Analyst',
      vertical: { name: 'Customer Support' },
      imgId: '61'
   }),
   new VerticalJob({
      name: 'IT Support Engineer',
      vertical: { name: 'Customer Support' },
      imgId: '66'
   }),
   new VerticalJob({
      name: 'Client Success Director',
      vertical: { name: 'Customer Support' },
      imgId: '57'
   }),
   new VerticalJob({
      name: 'Client Support Administrator',
      vertical: { name: 'Customer Support' },
      imgId: '62'
   }),
   new VerticalJob({
      name: 'Delivery Manager',
      vertical: { name: 'Customer Support' },
      imgId: '59'
   }),
   new VerticalJob({
      name: 'Desktop Support Manager',
      vertical: { name: 'Customer Support' },
      imgId: '56'
   }),
   new VerticalJob({
      name: 'Desktop Support Specialist',
      vertical: { name: 'Customer Support' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'Desktop Support Technician',
      vertical: { name: 'Customer Support' },
      imgId: '41'
   }),
   new VerticalJob({
      name: 'Engagement Director',
      vertical: { name: 'Customer Support' },
      imgId: '40'
   }),
   new VerticalJob({
      name: 'Escalation Manager',
      vertical: { name: 'Customer Support' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'IT Support Manager',
      vertical: { name: 'Customer Support' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'IT Support Specialist',
      vertical: { name: 'Customer Support' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Relationship Manager',
      vertical: { name: 'Customer Support' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Service Delivery Manager',
      vertical: { name: 'Customer Support' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Service Desk Operator',
      vertical: { name: 'Customer Support' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'Support Engineer',
      vertical: { name: 'Customer Support' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'Technical Account Manager',
      vertical: { name: 'Customer Support' },
      imgId: '61'
   }),
   new VerticalJob({
      name: 'Game Designer',
      vertical: { name: 'Design' },
      imgId: '65'
   }),
   new VerticalJob({
      name: 'Graphics Designer',
      vertical: { name: 'Design' },
      imgId: '59'
   }),
   new VerticalJob({
      name: 'UI Designer',
      vertical: { name: 'Design' },
      imgId: '56'
   }),
   new VerticalJob({
      name: 'Web Designer',
      vertical: { name: 'Design' },
      imgId: '55'
   }),
   new VerticalJob({
      name: 'UX Architect',
      vertical: { name: 'Design' },
      imgId: '51'
   }),
   new VerticalJob({
      name: '3D Graphics Engineer',
      vertical: { name: 'Design' },
      imgId: '54'
   }),
   new VerticalJob({
      name: 'Digital Animation Artist',
      vertical: { name: 'Design' },
      imgId: '52'
   }),
   new VerticalJob({
      name: 'Logo Designer',
      vertical: { name: 'Design' },
      imgId: '48'
   }),
   new VerticalJob({
      name: 'Game Graphics Designer',
      vertical: { name: 'Design' },
      imgId: '49'
   }),
   new VerticalJob({
      name: 'Video Animations Expert',
      vertical: { name: 'Design' },
      imgId: '40'
   }),
   new VerticalJob({
      name: '.NET Developer',
      vertical: { name: 'Development' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Agile Coach',
      vertical: { name: 'Development' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Agile Project Manager',
      vertical: { name: 'Development' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'Application Developer',
      vertical: { name: 'Development' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Application Manager',
      vertical: { name: 'Development' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Back-end Developer',
      vertical: { name: 'Development' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Build Engineer',
      vertical: { name: 'Development' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Build Manager',
      vertical: { name: 'Development' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'DevOps Engineer',
      vertical: { name: 'Development' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'DevOps Manager',
      vertical: { name: 'Development' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'Frameworks Specialist',
      vertical: { name: 'Development' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Front-end Designer',
      vertical: { name: 'Development' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'Front-end Developer',
      vertical: { name: 'Development' },
      imgId: '18'
   }),
   new VerticalJob({
      name: 'Full-Stack Developer',
      vertical: { name: 'Development' },
      imgId: '19'
   }),
   new VerticalJob({
      name: 'Game Architect',
      vertical: { name: 'Development' },
      imgId: '20'
   }),
   new VerticalJob({
      name: 'Game Developer',
      vertical: { name: 'Development' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'Mobile Applications Developer',
      vertical: { name: 'Development' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Software Architect',
      vertical: { name: 'Development' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Software Development Manager',
      vertical: { name: 'Development' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Software Engineer',
      vertical: { name: 'Development' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Webmaster',
      vertical: { name: 'Development' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'Wordpress Developer',
      vertical: { name: 'Development' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'ElasticSearch Engineer',
      vertical: { name: 'Development' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'Intelligent Routing API Engineer',
      vertical: { name: 'Development' },
      imgId: '40'
   }),
   new VerticalJob({
      name: 'iCloud Software Engineer',
      vertical: { name: 'Development' },
      imgId: '41'
   }),
   new VerticalJob({
      name: 'Scala Engineer',
      vertical: { name: 'Development' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'Full-Stack VB Developer',
      vertical: { name: 'Development' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Python Cloud Developer',
      vertical: { name: 'Development' },
      imgId: '80'
   }),
   new VerticalJob({
      name: 'Full-Stack Web Developer',
      vertical: { name: 'Development' },
      imgId: '2'
   }),
   new VerticalJob({
      name: 'React Developer',
      vertical: { name: 'Development' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Golang Software Engineer',
      vertical: { name: 'Development' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'eCommerce Software Engineer',
      vertical: { name: 'Development' },
      imgId: '5'
   }),
   new VerticalJob({
      name: 'Clojure Software Engineer',
      vertical: { name: 'Development' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Android Developer',
      vertical: { name: 'Development' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'Full-Stack JavaScript Developer',
      vertical: { name: 'Development' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'MERN Stack Developer',
      vertical: { name: 'Development' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'MEAN Stack Developer',
      vertical: { name: 'Development' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Maps Search Infrastructure Engineer',
      vertical: { name: 'Development' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'Full-Stack Java Developer',
      vertical: { name: 'Development' },
      imgId: '18'
   }),
   new VerticalJob({
      name: 'VR (Virtual Reality) Developer',
      vertical: { name: 'Development' },
      imgId: '19'
   }),
   new VerticalJob({
      name: 'VR (Virtual Reality) Designer',
      vertical: { name: 'Development' },
      imgId: '20'
   }),
   new VerticalJob({
      name: 'C++ Software Architect',
      vertical: { name: 'Development' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'POS Applications Developer',
      vertical: { name: 'Development' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'iOS/MacOS Developer',
      vertical: { name: 'Development' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Microservices Integration Consultant',
      vertical: { name: 'Development' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Blockchain Developer',
      vertical: { name: 'Development' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'AR (Augmented Reality) Developer',
      vertical: { name: 'Development' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'AR (Augmented Reality) Designer',
      vertical: { name: 'Development' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'React Native Developer',
      vertical: { name: 'Development' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'C++ Developer',
      vertical: { name: 'Development' },
      imgId: '40'
   }),
   new VerticalJob({
      name: 'C++ Test Engineer',
      vertical: { name: 'Development' },
      imgId: '41'
   }),
   new VerticalJob({
      name: 'C# Developer',
      vertical: { name: 'Development' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'Audio Engineering Expert',
      vertical: { name: 'Development' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Firmware Developer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'FPGA Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '18'
   }),
   new VerticalJob({
      name: 'RPA Developer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '19'
   }),
   new VerticalJob({
      name: 'Hardware Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '20'
   }),
   new VerticalJob({
      name: 'Embedded Testing Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'IC Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Digital Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Analog Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Hardware Modeling Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Embedded Software Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'Infotainment Software Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'RF Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'SOC Power Management Architect',
      vertical: { name: 'Embedded Engineering' },
      imgId: '40'
   }),
   new VerticalJob({
      name: 'Thermal Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '41'
   }),
   new VerticalJob({
      name: 'SoC Memory Controller Architect',
      vertical: { name: 'Embedded Engineering' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'Package Design and Development Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'SOC RTL Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '18'
   }),
   new VerticalJob({
      name: 'CPU Physical Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '19'
   }),
   new VerticalJob({
      name: 'SRAM Circuit Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '20'
   }),
   new VerticalJob({
      name: 'CPU Performance Architect',
      vertical: { name: 'Embedded Engineering' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'Signal Integrity Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Physical Design Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Performance Analysis Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Coherent Fabric RTL Architect',
      vertical: { name: 'Embedded Engineering' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Coherent IO RTL Architect',
      vertical: { name: 'Embedded Engineering' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'SOC RTL Integration Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'Power Integrity Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'SOC Modeling Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '40'
   }),
   new VerticalJob({
      name: 'ASIC Chip Engineer',
      vertical: { name: 'Embedded Engineering' },
      imgId: '41'
   }),
   new VerticalJob({
      name: 'ERP Analyst',
      vertical: { name: 'ERP' },
      imgId: '59'
   }),
   new VerticalJob({
      name: 'SAP Developer',
      vertical: { name: 'ERP' },
      imgId: '1'
   }),
   new VerticalJob({
      name: 'Change Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '61'
   }),
   new VerticalJob({
      name: 'Solution Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '66'
   }),
   new VerticalJob({
      name: 'Configuration Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '62'
   }),
   new VerticalJob({
      name: 'Data Center Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '64'
   }),
   new VerticalJob({
      name: 'Data Center Support Specialist',
      vertical: { name: 'IT & Operations' },
      imgId: '55'
   }),
   new VerticalJob({
      name: 'Data Migration Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '51'
   }),
   new VerticalJob({
      name: 'DBA Database Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '53'
   }),
   new VerticalJob({
      name: 'Database Developer',
      vertical: { name: 'IT & Operations' },
      imgId: '52'
   }),
   new VerticalJob({
      name: 'Enterprise Solutions Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '54'
   }),
   new VerticalJob({
      name: 'Technology Trainer',
      vertical: { name: 'IT & Operations' },
      imgId: '50'
   }),
   new VerticalJob({
      name: 'Integration Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '47'
   }),
   new VerticalJob({
      name: 'IT Analyst',
      vertical: { name: 'IT & Operations' },
      imgId: '49'
   }),
   new VerticalJob({
      name: 'IT Auditor',
      vertical: { name: 'IT & Operations' },
      imgId: '45'
   }),
   new VerticalJob({
      name: 'IT Coordinator',
      vertical: { name: 'IT & Operations' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'IT Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '44'
   }),
   new VerticalJob({
      name: 'IT Risk Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'IT Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '35'
   }),
   new VerticalJob({
      name: 'IT Operations Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '37'
   }),
   new VerticalJob({
      name: 'IT Systems Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'ITIL Consultant',
      vertical: { name: 'IT & Operations' },
      imgId: '31'
   }),
   new VerticalJob({
      name: 'ITSM Consultant',
      vertical: { name: 'IT & Operations' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'Process Analyst',
      vertical: { name: 'IT & Operations' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'SQL Developer',
      vertical: { name: 'IT & Operations' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Systems Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '24'
   }),
   new VerticalJob({
      name: 'Systems Analyst',
      vertical: { name: 'IT & Operations' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Systems Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '61'
   }),
   new VerticalJob({
      name: 'Technology Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '66'
   }),
   new VerticalJob({
      name: 'UNIX Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '62'
   }),
   new VerticalJob({
      name: 'Windows Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '64'
   }),
   new VerticalJob({
      name: 'Enterprise Applications Implementation Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '55'
   }),
   new VerticalJob({
      name: 'Operations Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '51'
   }),
   new VerticalJob({
      name: 'Linux Technical Support Analyst',
      vertical: { name: 'IT & Operations' },
      imgId: '53'
   }),
   new VerticalJob({
      name: 'NetSuite Consultant',
      vertical: { name: 'IT & Operations' },
      imgId: '52'
   }),
   new VerticalJob({
      name: 'Informatica Developer',
      vertical: { name: 'IT & Operations' },
      imgId: '54'
   }),
   new VerticalJob({
      name: 'Site Reliability Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '50'
   }),
   new VerticalJob({
      name: 'Mobile Engineering Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '47'
   }),
   new VerticalJob({
      name: 'Distributed Systems Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '49'
   }),
   new VerticalJob({
      name: 'Data Warehouse Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '45'
   }),
   new VerticalJob({
      name: 'Principal Infrastructure Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '46'
   }),
   new VerticalJob({
      name: 'Staff Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '44'
   }),
   new VerticalJob({
      name: 'VAS Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '39'
   }),
   new VerticalJob({
      name: 'Red Hat Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '35'
   }),
   new VerticalJob({
      name: 'SUSE Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '37'
   }),
   new VerticalJob({
      name: 'Enterprise Automation Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'PLC Design Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '31'
   }),
   new VerticalJob({
      name: 'NOC Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'Application Server Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Database Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Datacenter Administrator',
      vertical: { name: 'IT & Operations' },
      imgId: '24'
   }),
   new VerticalJob({
      name: 'Datacenter Architect',
      vertical: { name: 'IT & Operations' },
      imgId: '23'
   }),
   new VerticalJob({
      name: 'Datacenter Support Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '61'
   }),
   new VerticalJob({
      name: 'Digital Transformation Consultant',
      vertical: { name: 'IT & Operations' },
      imgId: '66'
   }),
   new VerticalJob({
      name: 'OSS Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '62'
   }),
   new VerticalJob({
      name: 'Email & Messaging Services Engineer',
      vertical: { name: 'IT & Operations' },
      imgId: '64'
   }),
   new VerticalJob({
      name: 'Release Manager',
      vertical: { name: 'IT & Operations' },
      imgId: '55'
   }),
   new VerticalJob({
      name: 'ALM Expert',
      vertical: { name: 'IT & Operations' },
      imgId: '51'
   }),
   new VerticalJob({
      name: 'Oracle Forms Developer',
      vertical: { name: 'IT & Operations' },
      imgId: '53'
   }),
   new VerticalJob({
      name: 'Network Administrator',
      vertical: { name: 'Networks' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Network Engineer',
      vertical: { name: 'Networks' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Network Analyst',
      vertical: { name: 'Networks' },
      imgId: '9'
   }),
   new VerticalJob({
      name: 'Network Architect',
      vertical: { name: 'Networks' },
      imgId: '21'
   }),
   new VerticalJob({
      name: 'GSM Solutions Designer',
      vertical: { name: 'Networks' },
      imgId: '35'
   }),
   new VerticalJob({
      name: 'Network Implementation Engineer',
      vertical: { name: 'Networks' },
      imgId: '3'
   }),
   new VerticalJob({
      name: 'Product Manager',
      vertical: { name: 'Product Management' },
      imgId: '1'
   }),
   new VerticalJob({
      name: 'Program Manager',
      vertical: { name: 'Product Management' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Project Manager',
      vertical: { name: 'Project Management' },
      imgId: '8'
   }),
   new VerticalJob({
      name: 'Information Security Analyst',
      vertical: { name: 'Security' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'Information Security Architect',
      vertical: { name: 'Security' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'IT security Specialist',
      vertical: { name: 'Security' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'Network Security Architect',
      vertical: { name: 'Security' },
      imgId: '19'
   }),
   new VerticalJob({
      name: 'Security Engineer',
      vertical: { name: 'Security' },
      imgId: '20'
   }),
   new VerticalJob({
      name: 'Security Testing Engineer',
      vertical: { name: 'Security' },
      imgId: '21'
   }),
   new VerticalJob({
      name: 'Infrastructure Security Manager',
      vertical: { name: 'Security' },
      imgId: '33'
   }),
   new VerticalJob({
      name: 'Security Compliance Manager',
      vertical: { name: 'Security' },
      imgId: '30'
   }),
   new VerticalJob({
      name: 'Application Security Engineer',
      vertical: { name: 'Security' },
      imgId: '27'
   }),
   new VerticalJob({
      name: 'Cyber Security Scrum Master',
      vertical: { name: 'Security' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'Cyber Intelligence Specialist',
      vertical: { name: 'Security' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'IoT Security Specialist',
      vertical: { name: 'Security' },
      imgId: '31'
   }),
   new VerticalJob({
      name: 'IT Security Architect',
      vertical: { name: 'Security' },
      imgId: '37'
   }),
   new VerticalJob({
      name: 'Security Auditor',
      vertical: { name: 'Security' },
      imgId: '17'
   }),
   new VerticalJob({
      name: 'Security Operations Engineer',
      vertical: { name: 'Security' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'Vulnerability Assessor',
      vertical: { name: 'Security' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'Disaster Recovery Specialist',
      vertical: { name: 'Storage & Backup' },
      imgId: '50'
   }),
   new VerticalJob({
      name: 'Storage Engineer',
      vertical: { name: 'Storage & Backup' },
      imgId: '32'
   }),
   new VerticalJob({
      name: 'Storage & Backup Administrator',
      vertical: { name: 'Storage & Backup' },
      imgId: '29'
   }),
   new VerticalJob({
      name: 'Data & Disaster Recovery Specialist',
      vertical: { name: 'Storage & Backup' },
      imgId: '6'
   }),
   new VerticalJob({
      name: 'Accessibility Specialist',
      vertical: { name: 'Test & QA' },
      imgId: '26'
   }),
   new VerticalJob({
      name: 'Quality Assurance (QA) Manager',
      vertical: { name: 'Test & QA' },
      imgId: '25'
   }),
   new VerticalJob({
      name: 'Test Engineer - Software',
      vertical: { name: 'Test & QA' },
      imgId: '16'
   }),
   new VerticalJob({
      name: 'Test Automation Engineer',
      vertical: { name: 'Test & QA' },
      imgId: '15'
   }),
   new VerticalJob({
      name: 'Neural Networks Test Engineer',
      vertical: { name: 'Test & QA' },
      imgId: '18'
   }),
   new VerticalJob({
      name: 'Chief Information Officer (CIO)',
      vertical: { name: 'Top Management' },
      imgId: '4'
   }),
   new VerticalJob({
      name: 'Chief Security Officer (CSO)',
      vertical: { name: 'Top Management' },
      imgId: '7'
   }),
   new VerticalJob({
      name: 'Chief Technology Officer (CTO)',
      vertical: { name: 'Top Management' },
      imgId: '21'
   }),
   new VerticalJob({
      name: 'Director of Delivery',
      vertical: { name: 'Top Management' },
      imgId: '28'
   }),
   new VerticalJob({
      name: 'Director of Information Systems',
      vertical: { name: 'Top Management' },
      imgId: '34'
   }),
   new VerticalJob({
      name: 'Director of Operations',
      vertical: { name: 'Top Management' },
      imgId: '36'
   }),
   new VerticalJob({
      name: 'Sales Director',
      vertical: { name: 'Top Management' },
      imgId: '38'
   }),
   new VerticalJob({
      name: 'Director of Product and Application Security',
      vertical: { name: 'Top Management' },
      imgId: '37'
   }),
   new VerticalJob({
      name: 'Director of Engineering',
      vertical: { name: 'Top Management' },
      imgId: '43'
   }),
   new VerticalJob({
      name: 'Director Of Digital Transformation',
      vertical: { name: 'Top Management' },
      imgId: '44'
   }),
   new VerticalJob({
      name: 'Chief Information Security Officer (CISO)',
      vertical: { name: 'Top Management' },
      imgId: '47'
   }),
   new VerticalJob({
      name: 'Chief Security Officer (CSO)',
      vertical: { name: 'Top Management' },
      imgId: '64'
   })]
